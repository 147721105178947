import { template as template_adc782e3be0148ef891a9c0abd9f63f9 } from "@ember/template-compiler";
const FKText = template_adc782e3be0148ef891a9c0abd9f63f9(`
  <p class="form-kit-text" ...attributes>
    {{yield}}
  </p>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKText;
