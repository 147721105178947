import { template as template_f054d5f27f8f42e3b082e157abbfd9fe } from "@ember/template-compiler";
import SortableColumn from "./sortable-column";
const OpLikesCell = template_f054d5f27f8f42e3b082e157abbfd9fe(`
  <SortableColumn
    @sortable={{@sortable}}
    @number="true"
    @order="op_likes"
    @activeOrder={{@activeOrder}}
    @changeSort={{@changeSort}}
    @ascending={{@ascending}}
    @name="likes"
  />
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default OpLikesCell;
