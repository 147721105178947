import { template as template_98d34f1c67c04a8faeadd7dc9de9f06d } from "@ember/template-compiler";
const FKLabel = template_98d34f1c67c04a8faeadd7dc9de9f06d(`
  <label for={{@fieldId}} ...attributes>
    {{yield}}
  </label>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKLabel;
